* {
  box-sizing: border-box;
}

@import './lib/animate.scss';

html,
body {
  height: 100%;
  overflow: hidden;
}

.App {
  width: 100%;
  overflow: hidden;
  height: 100%;
  position: relative;

  --swiper-theme-color: #919497;

  .image {
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
  }

  p {
    margin: 0;
  }

  .page {
    height: 100%;
    position: relative;
    overflow: hidden;

    .page-title {
      position: absolute;
      top: 50px;
      z-index: 2;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;

      p:first-child {
        font-weight: bold;
        font-size: 26px;
        color: #000;
      }

      p:last-child {
        font-size: 20px;
        margin-top: 13px;
        color: #999;
      }

      &.white {
        p {
          color: #fff;
        }
      }
    }
  }

  .content {
    width: 1180px;
    margin: 0 auto;
    position: relative;
  }

  .swiper-container-horizontal {
    height: 100%;

    .swiper-pagination-bullets {
      bottom: 40px;
    }
  }

  span.swiper-pagination-bullet {
    width: 70px;
    background: #fff;
    border-radius: 0;
    height: 3px;
  }

  .nav-bar {
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 100;
    z-index: 101;

    .wrapper {
      width: 100%;
      height: 100%;
      background: #fff;
      transform: translateY(-100px);
      transition: all 1s;
      display: flex;
      align-items: center;
    }

    &.show {
      .wrapper {
        transform: translateY(0);
      }
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .logo {
      background-image: url('./assets/images/logo.png');
      background-size: contain;
      width: 150px;
      height: 26px;
      cursor: pointer;
    }

    ul {
      display: flex;
      list-style: none;
      margin: 0;

      li {
        font-size: 13px;
        margin-left: 40px;
        cursor: pointer;

        &:hover {
          color: #4e690a;
        }
      }
    }
  }

  .green {
    color: #90be20;
  }

  h3 {
    margin-bottom: 2px;
  }

  .table-list {
    display: flex;
    flex-direction: column;

    &.white {
      color: #fff;
    }

    .table-item {
      padding: 0 32px;
      display: flex;
      flex-direction: row;
      font-size: 13px;
      flex: 1;
      align-items: center;

      &:nth-child(odd) {
        background: #fff;
      }

      &:nth-child(even) {
        background: rgba(0, 0, 0, 0.1);
      }

      .title {
        width: 88px;
      }

      .desc {
        flex: 1;
      }

      &.head {
        padding: 24px 32px;
        flex-direction: column;
        font-size: 15px;
        text-align: center;
        padding: 0 32px 10px;
      }
    }
  }

  .page1 {
    background-size: cover;

    &.active {

      .title1,
      .title2 {
        @extend .animate__animated;
        @extend .animate__fadeInDown;
      }

      .num-group {
        @extend .animate__animated;
        @extend .animate__fadeInUp;
      }
    }

    video {
      object-fit: cover;
      position: absolute;
    }

    &.image1 {
      background-image: url('./assets/images/page1Bg.png');
    }

    &.image2 {
      background-image: url('./assets/images/page1Image2.jpg');
    }

    &.image3 {
      background-image: url('./assets/images/page1Image3.jpg');
    }

    .title1 {
      font-size: 36px;
      font-weight: bold;
      color: #fff;
      margin-top: 300px;
      text-align: center;
    }

    .title2 {
      font-size: 28px;
      color: #fff;
      margin-top: 25px;
      text-align: center;
    }

    .num-group {
      display: flex;
      margin-top: 50px;

      .num-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        .num {
          color: #fff;
          font-size: 58px;
        }

        .title {
          font-size: 16px;
          color: #90be20;
          margin-top: 10px;
        }
      }
    }
  }

  .page2 {
    background: #141414;

    &.active {

      .desc {
        @extend .animate__animated;
        @extend .animate__fadeInLeft;
      }

      .desc2 {
        @extend .animate__animated;
        @extend .animate__fadeInRight;
      }
    }

    #map {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      z-index: 1;

      &::after {
        width: 200px;
        height: 130px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #242b38;
        z-index: 1000;
        content: '';
      }
    }

    .desc {
      position: absolute;
      z-index: 2;
      top: 550px;
      color: #fff;
      left: 0;

      .desc-title {
        color: #fff;
        font-weight: bold;
        font-size: 26px;
        z-index: 2;
        margin-bottom: 20px;
      }

      p {
        line-height: 30px;
        font-size: 16px;

        &:nth-child(3) {
          margin: 10px 0;
        }

        &:nth-child(2) {
          margin: 10px 0;
        }

        span {
          font-size: 24px;
          font-weight: bold;
          margin: 0 5px;
        }
      }
    }

    .desc2 {
      position: absolute;
      z-index: 2;
      top: 230px;
      right: 0px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .desc-item {
        color: #90be20;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;

        .num {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          border: 2px solid #90be20;
          line-height: 60px;
          font-size: 13px;
          text-align: center;
        }

        .word {
          font-size: 14px;
          margin-left: 15px;
          color: #fff;
        }
      }
    }
  }

  .page3 {
    &.active {

      .left-title,
      .right-card {
        @extend .animate__animated;
        @extend .animate__fadeInDown;
      }

      .left-word {
        @extend .animate__animated;
        @extend .animate__fadeInLeft;
      }

      .right-card-list {
        @extend .animate__animated;
        @extend .animate__fadeInRight;
      }
    }

    .up {
      // background-image: url('./assets/images/page3Bg.png');
      background-size: cover;
      width: 100%;
      height: 500px;
      color: #fff;

      .left-title {
        position: absolute;
        top: 220px;
        left: 100px;

        .title1 {
          font-size: 24px;
          font-weight: bold;
        }

        .title2 {
          font-size: 13px;
          margin-top: 30px;
          width: 340px;
        }
      }

      .right-card {
        width: 340px;
        height: 380px;
        background-color: #90be20;
        border-radius: 10px;
        position: absolute;
        right: 0;
        top: 75px;
        text-align: center;
        padding: 20px 80px;

        p:nth-child(1) {
          font-size: 26px;
          margin-top: 40px;
        }

        p:nth-child(2) {
          font-size: 20px;
          margin-top: 5px;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -53px;
            width: 80px;
            height: 7px;
            background: #fff;
          }
        }

        p:nth-child(3) {
          font-size: 26px;
          margin-top: 130px;
        }
      }
    }

    .down {

      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left-word {
          width: 250px;
          margin-right: 120px;

          .title {
            font-size: 20px;
            margin-bottom: 10px;
          }

          .desc {
            font-size: 13px;
          }
        }

        .right-card-list {
          .card-item {
            width: 730px;
            height: 140px;
            box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.08);
            display: flex;
            align-items: center;
            margin-top: 10px;
            justify-content: space-between;

            .left-image {
              width: 80px;
              height: 80px;
              border-radius: 50%;
              margin: 0 30px;
              background-size: cover;

              &.image1 {
                background-image: url('./assets/images/page3Image1.png');
              }

              &.image2 {
                background-image: url('./assets/images/page3Image2.png');
              }
            }

            .right {
              margin: 0 30px;
              width: 530px;

              .title {
                font-size: 14px;
              }

              .desc {
                font-size: 12px;
                margin-top: 18px;
              }
            }

          }
        }
      }
    }
  }

  .page4,
  .page5 {
    .content {
      display: flex;
      padding-top: 200px;

      .word {
        flex: 1;
        display: flex;
        flex-wrap: wrap;

        &.space {
          .word-item {
            margin: 0 50px;
            width: 186px;
          }
        }

        .word-item {
          width: 130px;
          margin-right: 65px;

          .title {
            font-size: 18px;
            color: #90BE20;
            display: flex;
            align-items: center;

            >span {
              display: inline-block;
              width: 22px;
              height: 22px;
              border-radius: 50%;
              background: #90BE20;
              color: #fff;
              text-align: center;
              font-size: 12px;
              line-height: 22px;
              margin-right: 6px;
            }
          }

          ul {
            font-size: 13px;
            list-style: none;
            padding: 0;
            margin-top: 20px;
            margin-bottom: 60px;

            li {
              line-height: 28px;
            }
          }
        }
      }

      .image1 {
        flex: 1;
        // background-image: url('./assets/images/page4Image1.png');
        background-size: contain;
      }

      .image2 {
        flex: 1;
        // background-image: url('./assets/images/page5Image1.png');
        background-size: contain;
      }
    }
  }

  .page4 {
    background: #f8f8f8;

    &.active {

      .word {
        @extend .animate__animated;
        @extend .animate__fadeInLeft;
      }

      .image1 {
        @extend .animate__animated;
        @extend .animate__fadeInRight;
      }
    }
  }

  .page5 {
    &.active {

      .word {
        @extend .animate__animated;
        @extend .animate__fadeInRight;
      }

      .image2 {
        @extend .animate__animated;
        @extend .animate__fadeInLeft;
      }
    }
  }

  .page6 {
    background: #141414;

    .content {
      display: flex;
      padding-top: 225px;

      .table-list,
      .page6Image {
        flex: 1;
      }

      .table-list {
        .table-item {
          &:nth-child(odd) {
            background-color: rgba(255, 255, 255, 0.1);
          }

          &:nth-child(even) {
            background-color: rgba(255, 255, 255, 0.2);
          }
        }
      }

      .page6Image {
        background-image: url('./assets/images/page6Image1.png');
        background-size: contain;
      }
    }
  }

  .page7 {
    .wrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &.active {

      .desc-list {
        @extend .animate__animated;
        @extend .animate__fadeInUp;
      }
    }

    .content {
      height: 100%;
      padding-top: 100px;
      padding-bottom: 20px;
    }

    .desc-list {
      // background-image: url('./assets/images/page7Image1.png');
      background-size: contain;
      height: 100%;
      position: relative;

      .desc {
        position: absolute;

        .s-title {
          font-size: 20px;
        }

        .list {
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          font-size: 13px;
          list-style: none;
          color: #4a4a4a;
          padding: 0;
          line-height: 28px;
        }

        &.desc1 {
          left: 309px;
          top: 50px;
        }

        &.desc2 {
          right: 330px;
          top: 50px;
        }

        &.desc3 {
          right: 134px;
          top: 310px;
        }

        &.desc4 {
          bottom: 0;
          left: 377px;
          width: 244px;
        }

        &.desc5 {
          bottom: 0;
          right: 330px;
        }
      }
    }
  }

  .page8 {
    // &.active {

    //   .left {
    //     @extend .animate__animated;
    //     @extend .animate__fadeInDown;
    //   }

    //   .right {
    //     @extend .animate__animated;
    //     @extend .animate__fadeInUp;
    //   }
    // }

    .content {
      height: 100%;
      padding-top: 150px;
    }

    .case-wrap {
      display: flex;
      height: 100%;
      width: 90%;
      margin: 0 auto;
    }

    .left {
      margin-right: 10px;
    }

    .right {
      margin-left: 10px;
    }

    .left,
    .right {
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: column;

      .desc {
        .desc-title {
          font-size: 20px;
          margin-bottom: 10px;
        }

        position: absolute;
        bottom: 0;
        background: #90be20;
        color: #fff;
        font-size: 12px;
        width: 100%;
        padding: 24px 32px;
        height: 144px;
        line-height: 22px;
      }
    }

    .image1 {
      // background-image: url('./assets/images/page8Image1.png');
      background-size: cover;
      width: 100%;
      height: 100%;
      position: relative;
    }

    .image2 {
      // background-image: url('./assets/images/page8Image2.png');
      background-size: cover;
      width: 100%;
      height: 100%;
      position: relative;
      flex: 1;
      margin-bottom: 10px;
    }

    .image3 {
      // background-image: url('./assets/images/page8Image3.png');
      background-size: cover;
      width: 100%;
      height: 100%;
      position: relative;
      flex: 1;
      margin-top: 10px;
    }

    .image4 {
      // background-image: url('./assets/images/page8Image4.png');
      background-size: cover;
      width: 100%;
      height: 100%;
      position: relative;
    }

    .image5 {
      // background-image: url('./assets/images/page8Image5.png');
      background-size: cover;
      width: 100%;
      height: 100%;
      position: relative;
    }
  }

  .page9 {
    background: #f8f8f8;

    // &.active {

    //   .table-list {
    //     @extend .animate__animated;
    //     @extend .animate__fadeInLeft;
    //   }

    //   .page9Image1 {
    //     @extend .animate__animated;
    //     @extend .animate__fadeInRight;
    //   }
    // }

    .content {
      height: 100%;
      padding-top: 150px;
      padding-bottom: 30px;

      .product-page {
        height: 100%;
        display: flex;
        width: 90%;
        margin: 0 auto;

        .table-list,
        .image {
          flex: 1;
        }
      }

      .page9Image1 {
        // background-image: url('./assets/images/page9Image1.png');
        background-size: cover;
      }

      .page9Image2 {
        // background-image: url('./assets/images/page9Image2.png');
        background-size: cover;
      }

      .page9Image3 {
        // background-image: url('./assets/images/page9Image3.png');
        background-size: cover;
      }
    }
  }

  .page10 {
    position: relative;

    .wrapper {
      position: relative;
    }

    &.active {

      .video {
        @extend .animate__animated;
        @extend .animate__fadeInLeft;
      }

      .desc {
        @extend .animate__animated;
        @extend .animate__fadeInRight;
      }

      .down {
        @extend .animate__animated;
        @extend .animate__fadeInUp;
      }
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 450px;
      background: #90be20;
    }

    .content {
      padding-top: 150px;

      .up {
        display: flex;
        color: #fff;

        .video,
        .desc {
          flex: 1;
        }

        .desc {
          margin-left: 40px;

          .desc-title {
            font-size: 24px;
            margin-bottom: 45px;
            margin-top: 20px;
          }

          .desc-detail {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }

      .down {
        position: relative;
        margin-top: 80px;

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 10px;
          background: #90be20;
        }

        .time-line-list {
          display: flex;
          justify-content: space-between;

          .time-line-item {
            width: 120px;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: #fff;
              z-index: 2;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
            }

            &::after {
              content: '';
              position: absolute;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background: rgba(144, 190, 32, 0.5);
              z-index: 1;
              left: 50%;
              transform: translateX(-50%);
              top: -15px;
            }

            &.big {
              width: 183px;

              .item-desc {
                line-height: 22px;
              }
            }

            .item-title {
              font-size: 20px;
              margin: 35px 0 10px;
            }

            .item-desc {
              font-size: 13px;
              line-height: 25px;
              color: #292929;
            }
          }
        }
      }
    }
  }

  .page11 {
    &.active {
      .content {
        @extend .animate__animated;
        @extend .animate__fadeInUp;
      }
    }

    .content {
      padding-top: 160px;
    }

    .tab-list {
      font-size: 14px;
      display: flex;
      justify-content: center;

      .tab-item {
        color: #999B93;
        border: 1px solid #dbdbdb;
        padding: 8px 25px;
        border-radius: 20px;
        margin: 0 11px;
        cursor: pointer;

        &.active {
          color: #fff;
          background: #90be20;
        }
      }
    }

    .active-image {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .image {
        width: 270px;
        height: 138px;
        background-size: cover;
      }
    }

    .image1 {
      background-image: url('./assets/images/11-1.png');
    }

    .image2 {
      background-image: url('./assets/images/11-2.png');
    }

    .image3 {
      background-image: url('./assets/images/11-3.png');
    }

    .image4 {
      background-image: url('./assets/images/11-4.png');
    }

    .image5 {
      background-image: url('./assets/images/11-5.png');
    }

    .image6 {
      background-image: url('./assets/images/11-6.png');
    }

    .image7 {
      background-image: url('./assets/images/11-7.png');
    }

    .image8 {
      background-image: url('./assets/images/11-8.png');
    }

    .image9 {
      background-image: url('./assets/images/11-9.png');
    }

    .image10 {
      background-image: url('./assets/images/11-10.png');
    }

    .image11 {
      background-image: url('./assets/images/11-11.png');
    }

    .image12 {
      background-image: url('./assets/images/11-12.png');
    }

    .image13 {
      background-image: url('./assets/images/11-13.png');
    }

    .image14 {
      background-image: url('./assets/images/11-14.png');
    }

    .image15 {
      background-image: url('./assets/images/11-15.png');
    }

    .image16 {
      background-image: url('./assets/images/11-16.png');
    }

    .image17 {
      background-image: url('./assets/images/11-17.png');
    }

    .image18 {
      background-image: url('./assets/images/11-18.png');
    }

    .image19 {
      background-image: url('./assets/images/11-19.png');
    }

    .image20 {
      background-image: url('./assets/images/11-20.png');
    }

    .image21 {
      background-image: url('./assets/images/11-21.png');
    }

    .image22 {
      background-image: url('./assets/images/11-22.png');
    }

    .image23 {
      background-image: url('./assets/images/11-23.png');
    }

    .image24 {
      background-image: url('./assets/images/11-24.png');
    }

    .image25 {
      background-image: url('./assets/images/11-25.png');
    }

    .image26 {
      background-image: url('./assets/images/11-26.png');
    }

    .image27 {
      background-image: url('./assets/images/11-27.png');
    }

    .image28 {
      background-image: url('./assets/images/11-28.png');
    }

    .image29 {
      background-image: url('./assets/images/11-29.png');
    }

    .image30 {
      background-image: url('./assets/images/11-30.png');
    }

    .image31 {
      background-image: url('./assets/images/11-31.png');
    }

    .image32 {
      background-image: url('./assets/images/11-32.png');
    }

    .image33 {
      background-image: url('./assets/images/11-33.png');
    }

    .image34 {
      background-image: url('./assets/images/11-34.png');
    }

    .image35 {
      background-image: url('./assets/images/11-35.png');
    }

    .image36 {
      background-image: url('./assets/images/11-36.png');
    }

    .image37 {
      background-image: url('./assets/images/11-37.png');
    }

    .image38 {
      background-image: url('./assets/images/11-38.png');
    }

    .image39 {
      background-image: url('./assets/images/11-39.png');
    }

    .image40 {
      background-image: url('./assets/images/11-40.png');
    }

    .image41 {
      background-image: url('./assets/images/11-41.png');
    }
  }

  .page12 {
    &.active {

      .content {
        @extend .animate__animated;
        @extend .animate__fadeInUp;
      }
    }

    .content {
      padding-top: 160px;
    }

    .tab-list {
      font-size: 14px;
      display: flex;
      justify-content: center;

      .tab-item {
        color: #999B93;
        border: 1px solid #dbdbdb;
        padding: 8px 25px;
        border-radius: 20px;
        margin: 0 11px;
        cursor: pointer;

        &.active {
          color: #fff;
          background: #90be20;
        }
      }
    }

    .image-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;

      .desc {
        font-size: 12px;
        color: #999;
      }
    }

    .active-image {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 20px;

      .image {
        width: 230px;
        height: 138px;
        background-size: contain;
      }
    }

    .bottom {
      position: absolute;
      height: 200px;
      color: #fff;
      background: #141414;
      width: 100%;
      bottom: 0;

      .content {
        padding: 0;

        .up {
          display: flex;
          justify-content: space-between;
          padding-top: 25px;

          .left {
            display: flex;
            font-size: 12px;

            .item {
              margin-left: 20px;
              line-height: 20px;

              p {
                cursor: pointer;

                &:first-child {
                  font-size: 14px;
                  font-weight: bold;
                  margin-bottom: 5px;
                }
              }
            }

            .logo {
              width: 150px;
              height: 30px;
              background-image: url('./assets/images/logo.png');
              background-size: contain;
            }

            .desc {
              font-size: 20px;
              margin-top: 20px;
            }
          }

          .center {
            font-size: 13px;
            line-height: 30px;
          }

          .right {
            display: flex;

            .code1 {
              background-image: url('./assets/images/code1.png');
              width: 100px;
              height: 100px;
              background-size: contain;
              margin-right: 20px;
            }

            .code2 {
              background-image: url('./assets/images/code2.png');
              width: 100px;
              height: 100px;
              background-size: contain;
            }
          }
          .downImg {

            position: absolute;
            width:100%;
            highet:80px;
            text-align: right;
            bottom: 2px;
            margin-top: 200px;
            margin-left: 200px;

            .img {
              width:9%;
              highet:9%;

            }
          }
          .downImg2 {

            position: absolute;
            width:100%;
            highet:100px;
            text-align: right;
            bottom: 2px;
            margin-top: 100px;
            margin-left: 170px;

            .imga {
              position: relative;

            }
            .imgdiv {
              width:100%;
              height:100%;
              position: absolute;
              top: 0px;
              left: 0px;

            }
            .imgembed {
              width:150px;
              height:50px;
            }
          }
        }

        .down {
          font-size: 12px;
          text-align: center;
        }


      }


    }

    .image1 {
      background-image: url('./assets/images/12-1.png');
    }

    .image2 {
      background-image: url('./assets/images/12-2.png');
    }

    .image3 {
      background-image: url('./assets/images/12-3.png');
    }

    .image4 {
      background-image: url('./assets/images/12-4.png');
    }

    .image5 {
      background-image: url('./assets/images/12-5.png');
    }

    .image6 {
      background-image: url('./assets/images/12-6.png');
    }

    .image7 {
      background-image: url('./assets/images/12-7.png');
    }

    .image8 {
      background-image: url('./assets/images/12-8.png');
    }

    .image9 {
      background-image: url('./assets/images/12-9.png');
    }

    .image10 {
      background-image: url('./assets/images/12-10.png');
    }

    .image11 {
      background-image: url('./assets/images/12-11.png');
    }

    .image12 {
      background-image: url('./assets/images/12-12.png');
    }

    .image13 {
      background-image: url('./assets/images/12-13.png');
    }

    .image14 {
      background-image: url('./assets/images/12-14.png');
    }

    .image15 {
      background-image: url('./assets/images/12-15.png');
    }

    .image16 {
      background-image: url('./assets/images/12-16.png');
    }

    .image17 {
      background-image: url('./assets/images/12-17.png');
    }

    .image18 {
      background-image: url('./assets/images/12-18.png');
    }


  }

  .page13 {
    &.active {
      .up {
        @extend .animate__animated;
        @extend .animate__fadeInDown;
      }

      .down {
        @extend .animate__animated;
        @extend .animate__fadeInUp;
      }
    }

    .content {
      padding-top: 80px;

      .up {
        .desc-list {
          display: flex;
          justify-content: space-between;

          .desc-item {
            width: 280px;

            .title {
              font-size: 20px;
            }

            .word {
              margin-top: 30px;
              font-size: 13px;
              color: #202020;
              line-height: 22px;
            }
          }
        }
      }

      .down {
        width: 100%;
        margin-top: 45px;

        .image1 {
          width: 100%;
          height: 400px;
          // background-image: url('./assets/images/13-1.png');
          background-size: contain;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .content {
      width: 100%;
    }

    .page3 .down .content .left-word {
      width: 250px;
      margin-right: 30px;
    }

    .page5 .content .word.space .word-item {
      margin: 0 5px;
    }

    .page7 .desc-list .desc.desc1 {
      left: 209px;
    }

    .page7 .desc-list .desc.desc2 {
      right: 230px;
    }

    .page7 .desc-list .desc.desc4 {
      left: 277px;
    }

    .page7 .desc-list .desc.desc5 {
      right: 230px;
    }

    .page4 .content .word .word-item {
      margin-right: 25px;
    }

    .page11 .active-image .image {
      width: 240px;
    }

    .page10 .content .up .desc .desc-title {
      margin-bottom: 10px;
    }
  }

  @media screen and (min-height: 950px) {
    .page .page-title {
      top: 150px;
    }

    .page10 .content {
      padding-top: 250px;
    }

    .page10::before {
      height: 550px;
    }

    .page2 .desc2 {
      top: 330px;
    }

    .page2 .desc {
      top: 650px;
    }

    .page3 .up {
      padding-top: 100px;
      height: 600px;
    }

    .page13 .content {
      padding-top: 180px;
    }

    .page4 .content,
    .page5 .content {
      padding-top: 300px;
    }

    .page7 .content {
      padding-top: 200px;
    }

    .page9 .content,
    .page8 .content {
      padding-top: 250px;
    }

    .page11 .content,
    .page12 .content {
      padding-top: 260px;
    }
  }

  @media screen and (max-height: 750px) {

    .page .page-title {
      top: 10px;
    }

    .page10 {
      .content {
        padding-top: 100px;
      }

      &::before {
        height: 350px;
      }

      video {
        width: 400px !important;
      }
    }

    .page10 .content .up .desc .desc-title {
      margin-bottom: 15px;
      margin-top: 0;
    }

    .page2 .desc2 {
      top: 130px;
    }

    .page2 .desc {
      top: 350px;
    }

    .page3 .up {
      height: 330px;
    }

    .page3 .up .right-card {
      top: 30px;
      width: 300px;
      height: 290px;
      padding: 0px 20px;
    }

    .page3 .up .right-card p:nth-child(3) {
      margin-top: 100px;
    }

    .page12 .active-image .image {
      width: 230px;
    }

    .page13 .content {
      padding-top: 40px;
    }

    .page13 .content .down {
      width: 60%;
      margin-top: 0px;
      margin: 0 auto;
    }

    .page11 .active-image .image {
      width: 230px;
      height: 138px;
      background-size: cover;
    }

    .page11 .content {
      padding-top: 120px;
    }

    .page3 .up .left-title {
      top: 120px;
    }

    .page12 .content {
      padding-top: 120px;
    }
  }
}
